import { Application } from '@splinetool/runtime'
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.css'
import { formValidation } from '../../base/js/formValidation'

const contactForm = document.getElementById('contact-form')
const canvas = document.getElementById('cubeCanvas')
if (contactForm) {
	const validation = formValidation(contactForm)
	const app = new Application(canvas)
	app.load('https://prod.spline.design/y2mIE9tMBN2qpLgf/scene.splinecode')

	validation.addControl('#email', [
		{
			rule: 'required'
		},
		{
			rule: 'email'
		}
	])
	validation.addControl('#text', [
		{
			rule: 'required'
		}
	])

	validation.onSuccess(async () => {
		event.preventDefault() // отключаем перезагрузку/перенаправление страницы
		try {
			// Формируем запрос
			const response = await fetch(event.target.action, {
				method: 'POST',
				body: new FormData(event.target)
			})
			// проверяем, что ответ есть
			if (!response.ok) {
				new Notify({
					status: 'error',
					title: 'Error!',
					text: 'An error occurred while sending the message!',
					effect: 'fade',
					speed: 300,
					customClass: '',
					customIcon: '',
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					notificationsGap: null,
					notificationsPadding: null,
					type: 'outline',
					position: 'right top',
					customWrapper: ''
				})
				contactForm.reset()
			}
			// проверяем, что ответ действительно JSON
			const contentType = response.headers.get('content-type')
			if (!contentType || !contentType.includes('application/json')) {
				new Notify({
					status: 'error',
					title: 'Error!',
					text: 'An error occurred while sending the message!',
					effect: 'fade',
					speed: 300,
					customClass: '',
					customIcon: '',
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					notificationsGap: null,
					notificationsPadding: null,
					type: 'outline',
					position: 'right top',
					customWrapper: ''
				})

				contactForm.reset()
			}
			// обрабатываем запрос
			const json = await response.json()
			if (json.result === 'success') {
				new Notify({
					status: 'success',
					title: 'Success!',
					text: 'Your message has been sent successfully!',
					effect: 'fade',
					speed: 300,
					customClass: '',
					customIcon: '',
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					notificationsGap: null,
					notificationsPadding: null,
					type: 'outline',
					position: 'right top',
					customWrapper: ''
				})

				contactForm.reset()
			} else {
				new Notify({
					status: 'error',
					title: 'Error!',
					text: 'An error occurred while sending the message!',
					effect: 'fade',
					speed: 300,
					customClass: '',
					customIcon: '',
					showIcon: true,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3000,
					notificationsGap: null,
					notificationsPadding: null,
					type: 'outline',
					position: 'right top',
					customWrapper: ''
				})
				contactForm.reset()
			}
		} catch (error) {
			// обработка ошибки
			alert(error)
		}
	})
}
