import JustValidate from 'just-validate'

const forms = document.querySelectorAll('form')

const updateClassList = (element, isValid) => {
	element.classList.toggle('!border-red', !isValid)
}

const handleOnValidate = ({ isSubmitted, fields }) => {
	if (!isSubmitted) return

	Object.keys(fields).forEach(key => {
		const { config, isValid } = fields[key]
		const { errorsContainer } = config
		const isDefaultFormControl = errorsContainer.classList.contains('form-control')
		const isSelectControl = errorsContainer.classList.contains('custom-select')
		const isCheckboxControl = errorsContainer.classList.contains('checkbox')

		if (isDefaultFormControl) {
			updateClassList(errorsContainer, isValid)
		}
		if (isSelectControl) {
			const customSelect = errorsContainer.querySelector('.custom-select-select')
			updateClassList(customSelect, isValid)
		}
		if (isCheckboxControl) {
			const checkbox = errorsContainer.querySelector('input[type="checkbox"]')
			updateClassList(checkbox, isValid)
		}
	})
}

export const formValidation = selector => {
	const instance = new JustValidate(selector)

	instance.onValidate(handleOnValidate)

	const addControl = (selector, rules) => {
		const elRef = instance.form.querySelector(selector)

		if (!selector) {
			throw new Error('No selector found')
		}

		if (!rules) {
			throw new Error('No rules found')
		}

		if (!instance) {
			throw new Error('No instance found')
		}

		if (!elRef) {
			throw new Error(`No field with selector ${selector} found`)
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error'],
			lockForm: true
		}

		instance.addField(selector, rules, config)
	}

	const onSuccess = cb => {
		instance.onSuccess(cb)
	}

	const revaildate = () => {
		instance.revalidate()
	}

	// Custom revaildate elements
	const customSelects = instance.form.querySelectorAll('.multi-select')

	customSelects.forEach(select => {
		select.addEventListener('change', () => {
			instance.revalidate()
		})
	})

	return {
		addControl,
		revaildate,
		onSuccess
	}
}
