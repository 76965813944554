import { Application } from '@splinetool/runtime'

const canvas = document.getElementById('canvasAbout')

if (canvas) {
  const app = new Application(canvas)

  app.load('https://prod.spline.design/defJ-j65oAleua1L/scene.splinecode')

}


