import { Application } from '@splinetool/runtime'

const canvas = document.getElementById('canvas3d')

if (canvas) {
  const app = new Application(canvas)

  app.load('https://prod.spline.design/dmvRo1y-1CGINIl8/scene.splinecode')

}
