import AOS from 'aos'

const textWrappers = document.querySelectorAll('.ml10 .letters')

textWrappers.forEach(textWrapper => {
	textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>")

	anime.timeline({ loop: true }).add({
		targets: '.ml10 .letter',
		rotateY: [-90, 0],
		opacity: [0, 1],
		easing: 'easeOutExpo',
		duration: 4000,
		delay: (el, i) => 100 * i
	})
})

AOS.init()
