document.querySelectorAll('a[href^="#"]').forEach(anchor => {
	anchor.addEventListener('click', function (e) {
		e.preventDefault()

		const targetId = this.getAttribute('href')
		const targetElement = document.querySelector(targetId)

		if (targetElement) {
			const offset = 40
			const elementPosition = targetElement.getBoundingClientRect().top
			const offsetPosition = elementPosition - offset

			window.scrollBy({
				top: offsetPosition,
				behavior: 'smooth'
			})
		} else {
			window.location.href = `/${targetId}`
		}
	})
})

document.addEventListener('DOMContentLoaded', () => {
	const scrollTopBtn = document.getElementById('scrollTop')
	const offsetToShowButton = 300
	let showTimeout
	let hideTimeout
	let isButtonVisible = false

	scrollTopBtn.style.opacity = '0'
	scrollTopBtn.style.pointerEvents = 'none'

	if (!scrollTopBtn) {
		console.error('Element with id "scrollTop" not found')
		return
	}

	function showScrollTopButton() {
		clearTimeout(hideTimeout)
		scrollTopBtn.style.opacity = '1'
		scrollTopBtn.style.pointerEvents = 'auto'
		isButtonVisible = true
	}

	function hideScrollTopButton() {
		clearTimeout(hideTimeout)
		scrollTopBtn.style.opacity = '0'
		scrollTopBtn.style.pointerEvents = 'none'

		isButtonVisible = false
	}

	window.addEventListener('scroll', () => {
		clearTimeout(showTimeout)
		showTimeout = setTimeout(() => {
			if (window.scrollY > offsetToShowButton) {
				showScrollTopButton()
			} else if (window.scrollY <= offsetToShowButton && isButtonVisible) {
				hideScrollTopButton()
			}
		}, 200)
	})

	scrollTopBtn.addEventListener('click', () => {
		hideScrollTopButton()
		window.scroll({
			top: 0,
			behavior: 'smooth'
		})
	})
})
