import Swiper from 'swiper'
import 'swiper/css'

import { Autoplay, Navigation, Pagination } from 'swiper/modules'

const qualificationSlider = new Swiper('.qualification-slider', {
	slidesPerView: 1,
	loop: true,
	spaceBetween: 30,
	autoplay: {
		speed: 3000,
		delay: 2000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true
	},

	speed: 1000,
	breakpoints: {
		768: {
			slidesPerView: 1,
			spaceBetween: 30
		},
		1024: {
			slidesPerView: 2,
			spaceBetween: 30
		},
		1312: {
			spaceBetween: 54,
			slidesPerView: 3
		}
	},
	modules: [Navigation, Pagination, Autoplay],
	navigation: {
		nextEl: document.querySelector('.qualification-slider-next'),
		prevEl: document.querySelector('.qualification-slider-prev')
	},
	pagination: {
		el: document.querySelector('.qualification-slider-pagination'),
		clickable: true
	}
})

const qualificationItems = document.querySelectorAll('.qualification-item')

qualificationItems.forEach(item => {
	item.addEventListener('click', () => {
		item.classList.toggle('flip')
	})
})
