const inputs = document.querySelectorAll('.input')

//Password toggle

const toggleInputBorder = input => {
	if (input.querySelector('input').value) {
		input.classList.add('!border-geyser')
	} else {
		input.classList.remove('!border-geyser')
	}
}

inputs.forEach(input => {
	toggleInputBorder(input)

	input.querySelector('input').addEventListener('focus', () => {
		input.classList.add('border-geyser')
		input.classList.remove('border-raven')
	})

	input.querySelector('input').addEventListener('blur', () => {
		input.classList.remove('border-geyser')
		input.classList.add('border-raven')

		toggleInputBorder(input)
	})
})
